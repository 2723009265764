<template>
  <div class="home">
      <div class="ad swiper-container" id="J_ad" v-if="bannerData && bannerData.length > 0">
          <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item,index) in bannerData" :key="index">
                <template v-if="item.openLink">
                    <a :href="item.openLink" >
                      <img :src="item.webImg" />
                    </a>
                </template>
                <template v-else-if="item.videoUrl">
                    <img :src="item.webImg" @click="showVideo(item.videoUrl)"/>
                    <span class="play" @click="showVideo(item.videoUrl)"></span>
                </template>
                <template v-else>
                    <img :src="item.webImg" />
                    
                </template>
              </div>
              
          </div>
          <!-- Add Pagination -->
          <div class="swiper-pagination"></div>
          <!-- Add Arrows -->
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
      </div>
      <div class="star-product" v-if="starProduct && starProduct.length > 0">
          <h3 class="title"></h3>
          <div :class="'item '+ ('item-'+(index+1)) " v-for="(item,index) in starProduct" :key="index" >
              <img :src="item.webImg" alt="">
              <div class="content">
                <p class="name">
                  <span class="desc" v-for="_item in item.title.split('\n')">
                    {{_item}}
                  </span>
                </p>
                <a  :href="'/star#'+ item.openLink" >Details</a>
              </div>
              
          </div>
          
      </div>
      <div class="product-box " v-if="whatsNew && whatsNew.length > 0">
          <div class="container ">
            <div class="bg"><div class="white"></div></div>
            <div class="product swiper-container " id="J_product">
                <div class="swiper-wrapper container">
                    <a class="swiper-slide" :href="item.openLink" v-for="item,index in whatsNew" :key="index" >
                      <img :src="item.webImg" alt="">
                      <span class="name">{{item.title}}</span>
                      <span class="desc">{{item.subTitle}}</span>
                    </a>
                  
                </div>
                
            </div>
            <!-- Add Arrows -->
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
          </div>
          
      </div>
      <div class="lab-product" v-if="lightMsrkLab && lightMsrkLab.length > 0">
          <h3 class="title"></h3>
          <div class="desc"></div>
          <p class="txt">小白光实验室主打Diamond + 的概念<br />
将珠宝与艺术、潮流、时尚、趣味融合，打造全新可持续珠宝理念<br />
为每一位追求极致的年轻人带来【普世闪耀】</p>
          <ul>
            
            <li :class="index%2 ==0 ? 'right':'left'" v-for="item,index in lightMsrkLab" :key="index">
                <template v-if="index%2 ==0">
                    <img :src="item.webImg" alt="">
                    <div class="content">
                        <h4 class="name">{{item.title}}</h4>
                        <p v-html="item.subTitle"></p>
                        <a :href="'/lml' + item.openLink " class="btn">Details</a>
                    </div>
                </template>
                <template v-else>
                    
                    <div class="content">
                        <h4 class="name">{{item.title}}</h4>
                        <p v-html="item.subTitle"></p>
                        <a :href="'/lml' + item.openLink " class="btn">Details</a>
                    </div>
                    <img :src="item.webImg" alt="">
                </template>
            </li>
            
            </li>
          </ul>
      </div>
      <div class="news-list" v-if="newsList && newsList.length > 0">
          <h3 class="title"></h3>
          <ul class="container">
            <li v-for="(item,index) in newsList" :key="index">
              <img :src="item.webImg" alt="" />
            </li>
            
          </ul>
          <router-link class="btn" :to="{name: 'Detail-List'} " >More</router-link>
      </div>
      <div class="map " v-if="showmap">
          
          <el-amap vid="amapDemo"  :center="center" :map-manager="AMapManager" :zoom="zoom"  :events="events"  style="width:100%; height:267px">
      </el-amap>
      </div>
      <div class="video-modal" v-if="show">
          <div class="video-box">
            <span class="video-close"  @click="show = false">x</span>
            <video-player  class="video-player vjs-custom-skin"
                ref="videoPlayer"
                :playsinline="true"
                :options="playerOptions"
            ></video-player>
          </div>
      </div>
  </div>
</template>

<script>
import Swiper from 'swiper';   
// import aMap from 'vue-amap'
import Utils from '@/api/Utils.js' 
import { AMapManager } from 'vue-amap';
export default {
  name: 'Home',
  data(){
    return {
      bannerData: null,
      newsList: null,
      starProduct: null,
      whatsNew: null,
      lightMsrkLab: null,
      zoom: 10,
      center: [121.47178, 31.24293],
      AMapManager,
     
      events: {
        
        init(map) {
          AMapUI.loadUI(['overlay/SimpleMarker'], function(SimpleMarker) {
            const marker = new SimpleMarker({
              iconLabel: 'A',
              iconStyle: 'red',
              map: map,
              position: map.getCenter()
            });
          });
          map.setMapStyle('amap://styles/whitesmoke')
        }
      },
      showmap: false,
      show: false,
      playerOptions : {
          playbackRates : [ 0.5, 1.0, 1.5, 2.0 ], //可选择的播放速度
          autoplay : true, //如果true,浏览器准备好时开始回放。
          muted : false, // 默认情况下将会消除任何音频。
          loop : false, // 视频一结束就重新开始。
          preload : 'preload', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language : 'zh-CN',
          aspectRatio : '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid : true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources : [ {
              type : "",
              src : ''//url地址
          } ],
          poster : "", //你的封面地址
          // width: document.documentElement.clientWidth,
          notSupportedMessage : '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar : {
              timeDivider : true,//当前时间和持续时间的分隔符
              durationDisplay : true,//显示持续时间
              remainingTimeDisplay : false,//是否显示剩余时间功能
              fullscreenToggle : true  //全屏按钮
          }
      }
    }
  },
  components: {
    
  },
  methods:{
    adSwiperInit(){
      new Swiper('#J_ad', { 
        pagination: {
          el: '.swiper-pagination',
          type: 'fraction'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        autoplay: {
          delay: 5000,
        },
        loop: true,
        type: 'fraction',
      
     });
    },
    productSwiperInit(){
      new Swiper('#J_product', { 
        slidesPerView: 4,
        loop: true,
        type: 'fraction',
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
     });
   
    },
    showVideo (url) {
      console.log(url )
      if(url){
        this.playerOptions.sources[0].src=url;
        
        this.show = true;
        setTimeout(()=>{
          this.$refs.videoPlayer.player.play();
        },700)
      }
    }
  },
  created(){
    
  },
  mounted(){
    
    Utils.post(`v1/outer/article/index`, {"bannerLimit": -1,"starLimit":-1,"lightLimit":-1,"whatsLimit":-1}).then((res)=>{
      if(res && res.code == 200){
        this.bannerData = res.data.indexBanner
        this.newsList = res.data.indexNewList
        this.starProduct = res.data.indexStarProducts
        this.whatsNew = res.data.indexWhatsNew
        this.lightMsrkLab = res.data.indexLightMsrkLab
        this.$nextTick(()=>{
      
          this.adSwiperInit();
          this.productSwiperInit();
        })
      }
    })

    setTimeout(()=>{
      this.showmap = true;
    },2000)
  }
}
</script>
<style lang="stylus" scoped>
@import url(./swiper.css);
.home{
  background #f7f8f8
}
.ad{
  margin-bottom: 105px;
  overflow: hidden;
  height: 538px;
  .play{
    position absolute
    left: 50%
    top: 50%
    transform translate3d(-50%,-50%,0)
    z-index: 3
    width 60px
    height 60px
    background: url('./play.png') 50% 0 no-repeat;
    background-size: 100% auto
    cursor pointer
  }
  .swiper-container {
      width: 100%;
      height: 538px;
      
  }
  .swiper-slide {
      width: 100%;
      height: 538px;
      display inline-block
      a,img{
        display: block
        height: 100%
        width: auto
        margin: 0 auto
      }
      a{
        width: 100%
      }
  }
  .swiper-button-prev{
      background: url('./left.png') 50% 0 no-repeat;
      background-size: 100% auto
      width 10px;
      height 14px;
      left: auto;
      right: 225px;
      top: auto;
      margin-top: 0;
      bottom: 66px;
  }
  .swiper-button-next{
      background: url('./right.png') 50% 0 no-repeat;
      background-size: 100% auto
      width 10px;
      height 14px;
      top: auto;
      margin-top: 0;
      bottom: 66px;
      right: 155px;
  }
  .swiper-pagination{
    font-size : 17px;
    color : #fff;
    width 70px;
    left: auto;
    right: 158px;
    bottom: 65px;
    text-align center
  }
}

.star-product{
    h3{
        background: url('./title1.png') 50% 0 no-repeat;
        height 31px;
        margin-bottom 10px
    }
    .item{
        width 853px;
        position relative;
        margin 0 auto 13px;
        img{
          
          margin: 0 auto
          display: block;
        }
        .content{
          position absolute
        }
        .name{
            font-weight: bold
            span{
              display block;
            }
            font-size: 12px;
            line-height 2;
        }
        a{
            width 107px;
            height 25px;
            line-height 25px;
            border 1px solid #d0d0d0;
            display block;
            border-radius 15px;
            text-align center;
            color: #3e3a39;
            margin-top 15px;
            &:hover,&.active{
              background : #353e66;
              border 1px solid #353e66;
              color : #fff;
            }
        }
        
    }
    .item-1{
      .content{
        left: 712px
        top: 260px;
      }
    }
    .item-2{
      .content{
        left: 82px
        top: 315px;
      }
    }
    .item-3{
      .content{
        left: 744px
        top: 325px;
      }
      
    }
}

.product-box{
  margin-top 130px;
  background #fff
  width 100%
  .container{
    width: 1048px;
    margin: 0 auto;
    padding-top 40px
    position relative
    padding-bottom 70px
    background #fff
    .bg{
        width: 1048px;
        height 78px;
        position absolute
        left: 0
        top: -40px;
        background:  url('./WhatsNew.png') 0 0 no-repeat;
    }
    .white{
      height 38px
      position absolute
      left: 0
      bottom: 0
      background rgba(255,255,255,.5);
      width 100%
    }
  }
  

  
  .swiper-button-prev{
      background:  url('./left-1.png') 50% 0 no-repeat;
      width 16px;
      height 19px;
      left: 0px;
      position absolute
      z-index: 2;
      &:hover{
        background:  url('./left-2.png') 50% 0 no-repeat;
      }
  }
  .swiper-button-next{
      background:  url('./right-1.png') 50% 0 no-repeat;
      width 16px;
      height 19px;
      right: 0px;
      position absolute
      z-index: 2;
       &:hover{
        background:  url('./right-2.png') 50% 0 no-repeat;
      }
  }
}
.product{
  margin-bottom: 200px;
  width 1048px;
  height: 260px;
  margin: 0 auto;
  .swiper-container {
      width: 100%;
      height: 260px;
      overflow : auto
  }
  .swiper-slide {
      width: 100%;
      height: 260px;
      text-align center
      display inline-block
      color #3e3a39
      img{
        height 160px;
        display block;
        margin: 0 auto;
        width: 173px
      }
      span{
        display block;
        margin: 0 auto;
        font-size: 12px;
      }
      .name{
        font-size: 12px;
        margin-bottom: 10px;
        // font-weight bold
      }
  }
}
.lab-product{
    h3{
        background: url('./title2.png') 50% 0 no-repeat;
        background-size: auto 31px
        height 31px;
        margin-top: 104px;
    }
    .desc{
        background: url('./lab-desc.jpg') 50% 0 no-repeat;
        height 100px;
        width: 853px;
        background-size: 100% auto ;
        margin: 31px auto 10px;
        text-align: center;
        line-height 100px;
        font-size: 12px;
        color: #3e3a39;
    }
    .txt{
      line-height 2;
      font-size : 12px;
      text-align center;
    }
    ul{
      width 830px;
      margin: 20px auto 0;
    }
    li{
      display flex;
      width 100%
      img{
          width: 400px;
          height 300px;
      }
       .content{
          flex: 1;
          text-align left;
          padding-top: 80px;
      }
      &.right{
          .content{
            margin-left: 90px;
          }
      }
      &.left{
         .content{
            margin-right: 90px;
            text-align right;
          }
      }
     
      .name{
          font-size: 17px;
          margin-bottom: 30px;
          // font-weight : bold
      }
      p{
        font-size: 12px;
        line-height 2;
      }
      .btn{
          width 110px;
          height 25px;
          line-height 25px;
          border 1px solid #d0d0d0;
          display: block;
          margin: 55px 0 0;
          border-radius 15px;
          text-align center;
          color: #3e3a39;
          display inline-block
          &:hover,&.active{
            background : #353e66;
            border 1px solid #353e66;
            color : #fff;
          }
        }
    }
}
.news-list{
    padding-bottom 70px
    ul{
      width 841px
    }
    h3{
        background: url('./title3.png') 50% 0 no-repeat;
        background-size auto 31px
        height 31px;
        margin-top: 110px;
        margin-bottom: 80px;
    }
   
    li{
        display inline-block
        width: 413px
        height 203px
        margin-bottom: 12px;
   
        &:nth-child(2n+1){
          margin-right: 14px;
        }
        img{
            display: block;
            width: 100%;
            height 100%;
        }
    }
    .btn{
          width 106px;
          height 24px;
          line-height 24px;
          border 1px solid #d0d0d0;
          display: block;
          margin: 55px auto 0;
          border-radius 15px;
          text-align center;
          color: #3e3a39;
          display block
          &:hover,&.active{
            background : #353e66;
            border 1px solid #353e66;
            color : #fff;
          }
        }
}
.video-modal{
  position fixed
  top: 0
  bottom: 0
  left: 0
  right: 0
  z-index: 10
  background rgba(0,0,0,.4)
  
  .video-box{
    width: 800px
    height: 450px
    position absolute
    top: 50%
    left: 50%
    transform translate3d(-50%,-50%,0)
  }
  .video-close{
    position absolute
    top: -17px
    right -17px
    width: 34px
    height: 34px
    border-radius 50%
    color: #fff
    line-height 30px
    font-size: 26px
    text-align center
    background red
    z-index 10
    cursor pointer
  }
  .video-player{
    width: 100%
    height: 100%
  }
}
</style>
<style lang="stylus">
  .vjs-custom-skin > .video-js .vjs-big-play-button{
    background url('./play.png') 50% 0 no-repeat
    background-size: cover
    border: none;
    width 60px
    height 60px!important
    padding: 0;
    margin-top -30px!important
    margin-left -30px!important
  }
  .video-js:hover .vjs-big-play-button,.vjs-custom-skin > .video-js .vjs-big-play-button:focus,.vjs-custom-skin > .video-js .vjs-big-play-button:active{
    background url('./play.png') 50% 0 no-repeat
    background-size: cover
  }
</style>